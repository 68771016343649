import { Carousel } from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import { type FC } from 'react';
import { Autoplay } from 'swiper/modules';

import OutcomeOne from '../../assets/images/homepage/outcomes/outcome01.png';
import OutcomeTwo from '../../assets/images/homepage/outcomes/outcome02.png';
import OutcomeThree from '../../assets/images/homepage/outcomes/outcome03.png';
import OutcomeFour from '../../assets/images/homepage/outcomes/outcome04.png';
import OutcomeFive from '../../assets/images/homepage/outcomes/outcome05.png';
import OutcomeSix from '../../assets/images/homepage/outcomes/outcome06.png';
import OutcomeSeven from '../../assets/images/homepage/outcomes/outcome07.png';
import OutcomeEight from '../../assets/images/homepage/outcomes/outcome08.png';
import OutcomeNine from '../../assets/images/homepage/outcomes/outcome09.png';
import OutcomeTen from '../../assets/images/homepage/outcomes/outcome10.png';
import OutcomeEleven from '../../assets/images/homepage/outcomes/outcome11.png';
import OutcomeTwelve from '../../assets/images/homepage/outcomes/outcome12.png';
import OutcomeThirteen from '../../assets/images/homepage/outcomes/outcome13.png';
import OutcomeFourteen from '../../assets/images/homepage/outcomes/outcome14.png';
import OutcomeFifteen from '../../assets/images/homepage/outcomes/outcome15.png';

const OutcomesProof = [
  OutcomeOne,
  OutcomeTwo,
  OutcomeThree,
  OutcomeFour,
  OutcomeFive,
  OutcomeSix,
  OutcomeSeven,
  OutcomeEight,
  OutcomeNine,
  OutcomeTen,
  OutcomeEleven,
  OutcomeTwelve,
  OutcomeThirteen,
  OutcomeFourteen,
  OutcomeFifteen,
  OutcomeOne,
  OutcomeTwo,
  OutcomeThree,
  OutcomeFour,
  OutcomeFive,
  OutcomeSix,
  OutcomeSeven,
  OutcomeEight,
  OutcomeNine,
  OutcomeTen,
  OutcomeEleven,
  OutcomeTwelve,
  OutcomeThirteen,
  OutcomeFourteen,
  OutcomeFifteen,
];

export const OutcomesMarquee: FC = () => (
  <Carousel
    hideControls
    swiperProps={{
      wrapperClass: 'transition-all duration-1000 ease-linear',
      speed: 3000,
      spaceBetween: 20,
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      loop: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
      preventInteractionOnTransition: true,
      modules: [Autoplay],
    }}
    items={OutcomesProof.map((outcome, index) => {
      return (
        <Image
          key={index}
          width={132}
          height={132}
          src={outcome}
          alt={`Customer outcome ${index}`}
        />
      );
    })}
  />
);
